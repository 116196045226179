import React, { useEffect, useState } from "react";
import { Radio, Button, message, Space } from "antd";
import {
    dynamicClear,
  dynamicRequest,
  remove_dues_mutation,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";

const RemoveDuesOptions = ({set_open,getUserExcel,id:excel_id}) => {
  const [selected_option, set_selected_option] = useState("");
  const { status, error, loading,id:success_id } = useDynamicSelector("removeDuetoDueby");
  const {id} = useDynamicSelector("user_excel");
  const dispatch = useDispatch();

  useEffect(() => {
    if (success_id) {
      message.success("Duetos removed successfully");
      dispatch(dynamicClear("removeDuetoDueby"));
      set_open(false);
      getUserExcel(excel_id);
    } else if (error) {
      message.error(error?.message);
      dispatch(dynamicClear("removeDuetoDueby"));
    }
  }, [status, error]);

  const handleOptionChange = (e) => {
    set_selected_option(e.target.value);
  };

  const handleCancel = () => {
    set_open(false);
  };

  const handle_remove_dues = () => {
    let key = [{ key: "removeDuetoDueby", loading: true }];
    let variables = {
     data:{
        user_excel_id: id,
        type: selected_option,
     }
    };
    dispatch(dynamicRequest(key, remove_dues_mutation, variables, "M"));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <div>
        <Radio.Group
          onChange={handleOptionChange}
          value={selected_option}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Radio value="due_to">Due to</Radio>
          <Radio value="due_by">Due by</Radio>
          <Radio value="both">Both</Radio>
        </Radio.Group>
      </div>
      <Space className="flex justify-flex-end">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" onClick={handle_remove_dues} loading={loading}>
          Submit
        </Button>
      </Space>
    </div>
  );
};

export default RemoveDuesOptions;
