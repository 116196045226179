import { showToast } from "@helpers/toast";
import { dynamicClear, dynamicRequest, society_change_password, useDynamicSelector } from "@services/redux";
import { Input, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ChangePasswordModal = ({ user_id,set_open }) => {
  const [society_code, set_society_code] = useState("");
  const dispatch = useDispatch();

const {status,error,loading} = useDynamicSelector("changeMigrationPassword")

  useEffect(()=>{
  if(status==="Success"){
    showToast({type:"success",message:"Password changed successfully"});
    dispatch(dynamicClear("changeMigrationPassword"));
    set_open(false)
  }
  else if(error){
    showToast({type:"error",message:error?.message});
    dispatch(dynamicClear("changeMigrationPassword"));
  }
  },[status,error])

  const get_society = () => {
    let society_key = [{ key: "changeMigrationPassword", loading: true }];
    let society_variables = { society_code,user_id };
    dispatch(
      dynamicRequest(society_key, society_change_password, society_variables)
    );
  };

  const handle_submit = () => {
    get_society();
  };

  return (
    <div className="change-password-society">
      <Input
        value={society_code}
        onChange={(e) => set_society_code(e.target.value)}
        placeholder="Society Code"
      />
      <Space className="submit-buttons">
        <Button variant="outlined" danger onClick={()=>set_open(false)}>
          Cancel
        </Button>
        <Button color="primary"  type="primary" onClick={handle_submit} loading={loading}>
          Submit
        </Button>
      </Space>
    </div>
  );
};

export default ChangePasswordModal;
