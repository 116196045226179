import {
  bridge_mutation,
  dynamicClear,
  dynamicRequest,
  get_one_society_query,
  remove_dues_mutation,
  third_level_validation,
  useDynamicSelector,
  user_excel_query,
} from "@services/redux";
import { Button, Card } from "antd";
import { Box, Center, HStack, Text, VStack } from "native-base";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import { retrieveItem } from "@helpers/storage";
import { showToast } from "@helpers/toast";
import AntdModal from "../ui/antd_modal";
import RemoveDuesOptions from "./remove_dues_options";

const ThirdLevelValidation = (props) => {
  const role = retrieveItem("role");
  console.log("role: ", role);
  const history = useHistory();
  const { t } = useTranslation();
  const { id, society_id } = useParams();
  const [errors, setErrors] = useState([]);
  const [error_count, setErrorCount] = useState(0);
  const [status, setStatus] = useState("");
  const [show_dueby_modal, set_show_dueby_modal] = useState("");

  const data = useDynamicSelector("user_excel");
  const { loading } = useDynamicSelector("push_to_bridge");
  const { loading: cbs_loading } = useDynamicSelector("dataMigrate");
  const { name, username } = useDynamicSelector("getSociety");
  const { status: remove_dues_status, error: remove_dues_error } =
    useDynamicSelector("remove_dues");
  console.log("data", data);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserExcel(id);
  }, [id]);

  // useEffect(() => {
  //   if (remove_dues_status === "Success") {
  //     showToast({ type: "success", message: t("removed_dues") });
  //     set_show_dueby_modal(false);
  //     dispatch(dynamicClear(""));
  //   } else if (remove_dues_error) {
  //     showToast({ type: "error", message: remove_dues_error?.message });
  //     dispatch(dynamicClear(""));
  //   }
  // }, [remove_dues_error, remove_dues_status]);

  useEffect(() => {
    getSociety();
  }, [society_id]);

  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: society_id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };
  useEffect(() => {
    if (data?.id) {
      //   let status = renderStatus(data);
      //   setStatus(status);
      let temp = [];
      if (data.third_level_validation) {
        temp = JSON.parse(data.third_level_validation);
        console.log("temp", temp);
        setErrorCount(temp.length);
        setErrors(temp);

        // if (status === "Data Error") {
        //   const groupedData = temp.reduce((acc, entry) => {
        //     const { sheet_name, row_number } = entry;
        //     if (!acc[sheet_name]) {
        //       acc[sheet_name] = {};
        //     }
        //     if (!acc[sheet_name][row_number]) {
        //       acc[sheet_name][row_number] = [];
        //     }
        //     acc[sheet_name][row_number].push(entry);
        //     return acc;
        //   }, {});
        //   console.log("groupedData1", temp);
        //   setErrors(groupedData);
        // } else if (status === "Column Error") {
        //   const groupedData = temp.reduce((acc, entry) => {
        //     const { sheet_name } = entry;
        //     if (!acc[sheet_name]) {
        //       acc[sheet_name] = [];
        //     }
        //     acc[sheet_name].push(entry);
        //     return acc;
        //   }, {});
        //   console.log("groupedData1", temp);

        //   setErrors(groupedData);
        // } else if (status === "Sheet Error" && temp?.length) {
        //   setErrors(temp);
        // } else if (status === "Sheet Error") {
        //   setErrors([]);
        // }
      }
    }
  }, [data]);

  const getUserExcel = (id) => {
    let user_excel_key = [{ key: "user_excel", loading: true }];
    let user_excel_variables = {
      id: id,
    };
    dispatch(
      dynamicRequest(
        user_excel_key,
        third_level_validation,
        user_excel_variables
      )
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleBridgePush = () => {
    let bridge_key = [{ key: "push_to_bridge", loading: true }];
    let bridge_variables = {
      id,
    };
    dispatch(
      dynamicRequest(bridge_key, bridge_mutation, bridge_variables, "M")
    );
  };

  const handleCBSPush = () => {
    let cbs_key = [{ key: "dataMigrate", loading: true }];
    let cbs_variables = {
      society_id: id,
    };
    dispatch(dynamicRequest(cbs_key, cbs_mutation, cbs_variables, "M"));
  };

  const renderStatus = ({
    is_sheet_processing,
    is_column_processing,
    is_data_processing,
    is_sheet_valid,
    is_column_valid,
    is_valid,
  }) => {
    if (is_sheet_processing || is_column_processing || is_data_processing)
      return "Processing";
    if (is_sheet_valid && is_column_valid && is_valid) return "Valid File";
    if (is_sheet_valid && is_column_valid && !is_valid) return "Data Error";
    if (is_sheet_valid && !is_column_valid && !is_valid) return "Column Error";
    if (!is_sheet_valid && !is_column_valid && !is_valid) return "Sheet Error";
    return "Processing";
  };

  return (
    <Box flex={1} padding="5">
      {data.loading ? (
        <Loader
          type="spinner-default"
          bgColor={"#349481"}
          color={"#349481"}
          size={50}
        />
      ) : (
        <Box>
          <HStack justifyContent="flex-end">
            <Button type="default" danger onClick={handleBack}>
              Back
            </Button>
          </HStack>
          {role !== "Society" && (
            <Box top="10px" zIndex="2" mb="10px">
              <Text fontSize="md" fontWeight="bold">
                {name} - {username}
              </Text>
            </Box>
          )}
          <Text bold fontSize={"md"}>
            Response
          </Text>
          <Box marginTop="2">
            <Card
              style={{
                alignItems: "center",
              }}
            >
              <HStack flex="1">
                <Box flex="1">
                  <VStack space={"5"}>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:uploaded_date_time")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {data.uploaded_time
                            ? moment(new Date(Number(data.uploaded_time)))
                                .utcOffset("+05:30")
                                .format("DD/MM/YY hh:mm:ss a")
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:status")} : </Text>
                      </Box>
                      <Box>
                        <Text>{status} </Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:error_count")} : </Text>
                      </Box>
                      <Box>
                        <Text>
                          {error_count > 0 ? (
                            error_count
                          ) : (
                            <div>
                              We are analyzing your file and we will let you
                              know if there are any errors.
                              <br />
                              உங்களது தகவல்கள் சரிபார்க்கபடுகின்றன. ஏதேனும்
                              தவறுகள் இருப்பின் உங்களுக்கு தெரிவிக்கபடும்.
                            </div>
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
                <Box flex="1">
                  <Center my={"auto"}>
                    {status === "Valid File" && role === "Admin" ? (
                      <>
                        <Button
                          type="primary"
                          onClick={handleBridgePush}
                          loading={loading}
                        >
                          Push to Bridge
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    { role === "Admin" || role === "Zone Admin" ? (
                      <Button
                        type="primary"
                        onClick={() => set_show_dueby_modal(true)}
                        loading={cbs_loading}
                      >
                        {t("remove_dues")}
                      </Button>
                    ):""}
                  </Center>

                  {/* {role === "Admin" ||
                      (role === "Zone Executive" && (
                        
                      ))}  */}
                </Box>
              </HStack>
            </Card>
          </Box>

          <VStack>
            {errors?.map((x, i) => (
              <LazyLoad offset={1200} key={i}>
                <div key={i}>
                  <Box padding="3%">
                    <HStack flex={1}>
                      <Box width="30%">
                        {console.log("xxxx", x)}

                        <Text bold>{t("table:error_type")} : </Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{x.error_type} </Text>
                      </Box>
                    </HStack>

                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:sheet_name")} : </Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{x?.sheet_name} </Text>
                      </Box>
                    </HStack>

                    <HStack>
                      <Box width="30%">
                        <Text bold>{t("table:error_message")} : </Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{x.error}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </div>
              </LazyLoad>
            ))}
          </VStack>
        </Box>
      )}

      <AntdModal
        isOpen={show_dueby_modal}
        onClose={() => set_show_dueby_modal(false)}
        width={450}
        header={"Remove Dues"}
        component={<RemoveDuesOptions set_open={set_show_dueby_modal} id={id} getUserExcel={getUserExcel}/>}
      />
    </Box>
  );
};
export default ThirdLevelValidation;
